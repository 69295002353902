<template>
	<div class="reading_container">
		<div class="reading_info_contents">
			<div class="title">[input] Input Field</div>
			<div class="Board">
				<table class="Board_type1">
					<colgroup><col width="0">
						<col width="200">
						<col width="350">
						<col width="*">
					</colgroup>
					<tbody>
						<tr><td></td>
							<td class="level2">자동완성<br/>(고정목록)</td>
							<td class="source" v-pre>&lt;AutoComplete type="text" v-model="text" title="Input Field" :list="list" @autoComplete="autoComplete"&gt;<br/>&lt;template v-slot:autoComplete="autoComplete"&gt;<br/>{{autoComplete.item.text}}<br/>&lt;/template&gt;<br/>&lt;/AutoComplete&gt;</td>
							<td class="sample">
							<div>text : {{list.text}}<br/>list : {{list.list}}<br/>result : {{list.result}}</div>
								<AutoComplete type="text" v-model="list.text" title="Input Field" :list="list.list" listText="text" @autoComplete="autoComplete">
									<template v-slot:autoComplete="autoComplete">{{autoComplete.item.text}}</template>
								</AutoComplete>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">자동완성<br/>(동적목록)</td>
							<td class="source">
								&lt;AutoComplete type="text" v-model="text" title="Input Field" :list="list" @keyup="inputListKeyup"/&gt;<br/>
								keyup 이벤트에서 list의 목록을 변경.
							</td>
							<td class="sample">
								<div>text : {{list2.text}}<br/>list : {{list2.list}}<br/>result : {{list2.text}}</div>
								<AutoComplete type="text" title="Input Field" :list="list2.list" @keydown="inputList2Keydown"  @keyup="inputList2Keyup" @autoComplete="autoComplete2"/>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">달력</td>
							<td class="source">
								&lt;CalendarComp ref="calendar" v-model="date" :start="start" :end="end" :layer="layer"/&gt;<br/><br/>
								&lt;InputComp type="date" v-model="date" title="date" format="yyyy-MM-dd" :start="start" :end="end"/&gt;
							</td>
							<td class="sample">
								date : {{calendar.date | date}} | start : {{calendar.start}} | end : {{calendar.end | date}} | layer : {{calendar.layer}}
								<button @click="$refs.calendar.open()">열기</button><br/>
								<CalendarComp ref="calendar" v-model="calendar.date" :start="calendar.start" :end="calendar.end" :layer="calendar.layer"/><br/>
								<InputComp type="date" v-model="calendar.date" title="date" format="yyyy-MM-dd" :start="calendar.start" :end="calendar.end"/><br/>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">Select</td>
							<td class="source">
							</td>
							<td class="sample">
								<SelectComp type="select" v-model="select" cdId="PRO101"/>
								<div><InputComp v-model="select" /></div>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">CheckBox</td>
							<td class="source">
							</td>
							<td class="sample">
								<SelectComp type="checkbox" v-model="checkbox" cdId="PRO101"/>
								<div><span v-for="(cd, idx) in checkbox" :key="cd" @click="checkbox.splice(idx, 1)">{{cd}} </span></div>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">Radio</td>
							<td class="source">
							</td>
							<td class="sample">
								<SelectComp type="radio" v-model="radio" cdId="PRO101"/>
								<div><InputComp v-model="radio" /></div>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">SelectDate</td>
							<td class="source">
							</td>
							<td class="sample">
								<SelectDate v-model="ym" type="date" term="Y-10"/>
								{{ ym }}
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">SelectFilter</td>
							<td class="source" v-pre>
								공통코드값으로 조회하는 검색필터<br/>
								Ex) &lt;SearchFilter name="기술등급" cdId="PRO106" v-model="PRO106" /&gt;									
							</td>

							<td class="sample">
								<SearchFilter name="기술등급" cdId="PRO106" v-model="search" />
								{{ search }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'inputGuide',
	data() {
		return {
			list : {
				text : '가',
				list : [{code : 'KR1', text : '가나다'}, {code : 'KR2', text : '나다마'}, {code : 'KR3', text : '마가나'}, {code : 'KR0', text : '한글'}, {code : 'EN0', text : 'english'}, {code : 'NO1', text : '123'}],
				result : null
			},
			list2 : {
				text : '',
				list : []
			},
			calendar : {
				date  : new Date(),
				start : -1,
				end   : new Date().add( 10, 'day'),
				layer : true
			},
			select : '21',
			radio: '03',
			checkbox: ['02', '11'],
			search: '',
			ym: '202101',
		};
	},
	methods: {
		inputList2Keydown(){
			//console.log('inputList2Keydown', evt, evt.target.value);
		},

		inputList2Keyup(evt){
			//console.log('inputList2Keyup', evt, evt.target.value);
			var val = evt.target.value;
			setTimeout(()=>{
				this.list2.list = [];
				this.list2.list.push(val + 1);
				this.list2.list.push(val + 2);
				this.list2.list.push(val + 3);
			}, 500);
		},

		autoComplete(list, event){
			//console.log('autoComplete', list, event)
			this.list.result = event;
		},

		autoComplete2(item){
			this.list2.text = item;
		},
	},
};
</script>